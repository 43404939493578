<template>
  <div class="daily-supervision">
    <van-nav-bar
      :border="false"
      class="trouble-nav"
      left-arrow
      fixed
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">承包商日常监督</div>
      <div slot="right" class="nav-right" @click="gotoDetails('add')">添加</div>
    </van-nav-bar>
    <div class="search">
      <template>
        <van-search
          v-model="query.partnerName"
          class="search-bar"
          placeholder="请输入名称关键词或编号"
          @clear="onConfirm"
          @search="onConfirm"
        >
          <template #left-icon>
            <van-icon
              name="sousuo"
              class-prefix="iconfont"
              class="search-icon"
            />
          </template>
        </van-search>
        <date-time v-model="dateTime" :time-close="false" type="date" />
      </template>
      <div class="search-query">
        <fix-list-query query="query" @refresh="onQueryChange" />
        <div class="trouble-total">共{{ total }}条结果</div>
      </div>
    </div>
    <van-pull-refresh
      ref="daily-supervision-list"
      v-model="refreshing"
      class="daily-supervision-listBlock"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :error.sync="isError"
        :finished="finished"
        :finished-text="isError ? '' : '没有更多了'"
        error-text="加载失败，请稍后再试！"
        @load="onLoad"
      >
        <div
          v-for="(item, idx) in dailySupervisionList"
          :key="idx"
          class="content-item"
          @click="gotoDetails(item.id)"
        >
          <div class="top">
            <div>
              <div class="title">{{ item.scoreClassifyName }}</div>
              <div :class="['flag', item.partnerType === 1 ? 'green' : 'blue']">
                {{ item.partnerTypeName }}
              </div>
            </div>
            <div
              :class="[
                'score',
                Number(item.scoreValue) >= 0 ? 'success' : 'warning'
              ]"
            >
              {{ item.scoreValue }}
            </div>
          </div>
          <div class="bottom">
            <div class="compony">
              <van-icon
                class-prefix="iconfont"
                class="list-icon"
                color="#aeb3c0"
                name="bumen"
              />
              <span>{{ item.partnerName }}</span>
            </div>
            <div class="info" style="margin: 12px 0">
              {{ item.disposeDepartmentName }}
            </div>
            <div class="info">
              <span class="name">{{ item.disposeUserName }}</span>
              <span>{{ item.illegalDate }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getBadBehaviorRecordPage } from "@/api/basics/contractor";
import { getSelectTroubleStatus, getTroubleList } from "@/api/psm/trouble";
import FixListQuery from "./components/FixListQuery";
import { mapState } from "vuex";
export default {
  name: "DailySupervision",
  components: { FixListQuery },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  watch: {
    "query.partnerName": {
      handler(val) {
        // this.computedHeight();
        if (!val) return;
        this.onConfirm();
      },
      immediate: true
    },
    dateTime: {
      handler(val) {
        if (val.start && val.end) {
          this.query.startDate = val.start;
          this.query.endDate = val.end;
          this.onConfirm();
        }
        if (!val.start && !val.end) {
          this.query.startDate = "";
          this.query.endDate = "";
          this.onConfirm();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      // 改善查询参数
      dateTime: {
        start: "",
        end: ""
      },
      query: {
        page: 1,
        size: 10,
        sort: "illegalDate_desc;ctime_desc",
        partnerName: "",
        partnerType: "",
        poiId: "",
        scoreClassifyId: "",
        disposeDepartmentId: "",
        disposeUserId: "",
        subDepartment: true
      },
      // 下拉刷新，上拉加载
      loading: false,
      finished: false,
      refreshing: false,
      isError: false,
      total: 0,
      dailySupervisionList: []
    };
  },
  methods: {
    gotoDetails(id) {
      this.$router.push({
        name: "DailySupervisionDetails",
        params: { id }
      });
    },
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.onConfirm();
    },
    onConfirm() {
      this.query.page = 1;
      this.total = 0;
      this.dailySupervisionList = [];
      this.onLoad();
    },
    onRefresh() {
      // 清空列表数据
      this.query.page = 1;
      this.total = 0;
      this.onLoad();
    },
    async onLoad() {
      this.finished = false;
      this.loading = true;
      this.isError = false;
      if (this.refreshing) {
        this.dailySupervisionList = [];
        this.refreshing = false;
      }
      try {
        const { total, list } = await getBadBehaviorRecordPage(this.query);
        this.loading = false;
        this.total = total;
        // 当前列表数量等于总数 暂无内容
        if (list && list.length) {
          this.dailySupervisionList = this.dailySupervisionList.concat(list);
          // 加载成功下次页码加1
          this.query.page++;
        } else {
          this.finished = true;
        }
      } catch (error) {
        console.log("onLoad -> error", error);
        this.loading = false;
        this.finished = true;
        this.isError = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.daily-supervision {
  height: 100%;
  .search {
    padding-top: 58px;
    .search-bar {
      padding-top: 16px;
      padding-bottom: 12px;
      margin-bottom: -12px;
    }
    .trouble-total {
      text-align: center;
      color: #8c8f97;
      font-size: 14px;
      margin: 14px 0;
    }
  }
  .daily-supervision-listBlock {
    height: calc(100% - 256px);
    overflow: scroll;
  }
  .content-item {
    min-height: 90px;
    padding: 14px 16px 12px;
    border-bottom: 10px solid #f3f5fa;
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
      div:first-of-type {
        display: flex;
        // justify-content: space-between;
        align-items: flex-start;
        margin-right: 12px;
        .title {
          font-size: 16px;
          color: #2e2e4d;
          font-weight: 500;
          max-width: 66%;
        }
        .flag {
          font-size: 10px;
          color: #ffffff;
          padding: 3px 10px;
          border-radius: 2px;
          &.green {
            background: #23a86f;
          }
          &.blue {
            background: #1676ff;
          }
        }
      }
      .score {
        font-size: 20px;
        font-weight: 600;
        &.warning {
          color: #e56e0a;
        }
        &.success {
          color: #23a86f;
        }
      }
    }
    .bottom {
      font-size: 12px;
      color: #2e2e4d;
      line-height: 12px;
      .iconfont {
        margin-right: 6px;
      }
      .compony {
        font-size: 14px;
        color: #2e2e4d;
        line-height: 14px;
        display: flex;
        align-items: center;
      }
      .info {
        padding-left: 20px;
      }
      .name {
        margin-right: 16px;
      }
    }
  }
}
</style>
