<template>
  <div class="fix-list-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="fix-list-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="fix-list-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="fix-list-query__item-icon"
      />
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <!-- :department="form.department" -->
    <add-facility
      v-model="facilityMap"
      :visible="facilityVisible"
      activated="14"
      auth="org"
      title="违章地点"
      @close="facilityVisible = false"
      @confirm="onFacilityConfirm"
    />
    <select-person
      :choose-value="chooseValue"
      title="查处人"
      :visible="executorVisiable"
      position="bottom"
      :multiple="false"
      @close="executorVisiable = false"
      @confirm="onChooseExecutor"
    />
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import { getSelectScoreClassifyType } from "@/api/basics/contractor";
import { getSelectTroubleStatus } from "@/api/psm/trouble";
import getSelectMixin from "@/views/trouble/mixin/getSelectMixin";
import SelectPopup from "@/components/SelectPopup";
import CascadeDepartment from "@/components/CascadeDepartment";

export default {
  name: "FixListQuery",
  components: { CascadeDepartment, SelectPopup },
  mixins: [getSelectMixin],
  props: ["query"],
  computed: {
    chooseValue() {
      if (!this.queryInfo.disposeUserId) return [];
      return [
        {
          value: this.queryInfo.disposeUserId,
          label: this.queryInfo.disposeUserName
        }
      ];
    }
  },
  data() {
    return {
      queryInfo: {
        partnerType: "",
        poiId: "",
        scoreClassifyId: "",
        department: "",
        subDepartment: false, // cascade-department 组件没传 includeChildren 参数，默认false
        disposeUserId: ""
      },
      facilityMap: {
        label: "",
        value: "",
        type: ""
      },
      facilityVisible: false,
      executorVisiable: false,
      configs: [
        {
          title: "类型",
          type: "partnerType",
          types: "contractorTypeList"
        },
        {
          title: "违章地点",
          type: "poiId",
          types: "troubleStatus"
        },
        {
          title: "记分行为",
          type: "scoreClassifyId",
          types: "scoreClassifyTypeList"
        },
        {
          title: "查处部门",
          type: "disposeDepartmentId"
        },
        {
          title: "查处人",
          type: "disposeUserId"
        }
      ],
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      contractorTypeList: [
        {
          label: "承包商",
          value: "1"
        },
        {
          label: "承运商",
          value: "2"
        }
      ],
      scoreClassifyTypeList: [],
      troubleStatus: [],
      selectValue: "",
      popupValue: "",
      popupType: "",
      department: ""
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    onChooseExecutor(ids = [], list = []) {
      const item = list[0] || {};
      this.queryInfo.disposeUserId = item.value;
      this.queryInfo.disposeUserName = item.label;
      this.$emit("refresh", this.queryInfo);
    },
    //违章地点弹出层确认
    async onFacilityConfirm(facility) {
      // this.queryInfo.poiName = facility.label;
      // this.queryInfo.poiType = facility.type;
      this.queryInfo.poiId = facility.value;
      if (!facility || !Object.keys(facility).length || !facility.value) {
        this.queryInfo.poiId = "";
        // this.form.poiDepartmentName = "";
        // this.form.poiDepartmentId = "";
        // return false;
      }
      this.$emit("refresh", this.queryInfo);
    },
    refreshQuery(selectValue, includeInfo) {
      this.queryInfo.disposeDepartmentId = this.department;
      this.queryInfo.subDepartment = includeInfo?.include;
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        this.scoreClassifyTypeList = await getSelectScoreClassifyType({
          orgCode: this.userInfo.orgCode
        });
        this.troubleStatus = await getSelectTroubleStatus();
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }) {
      if (type === "disposeDepartmentId") {
        // this.selectValue = this.queryInfo.department;
        this.departmentVisible = true;
      } else if (type === "poiId") {
        this.facilityVisible = true;
      } else if (type === "disposeUserId") {
        this.executorVisiable = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.fix-list-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
